import React, { useEffect } from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import CartItemsContainer from "../components/cart/cart-items-container";
import SliderDrinks from "../components/slider-drinks";

const Cart = () => {
	useEffect(() => {
	document.getElementById('lang-switch').href="/pl/koszyk/"
	}, []);

	return (
		<Layout>
		<SEO
			title={ 'Cart' }
			header={ { siteTitle: ' Shroom | Shop' } }
		/>
			<CartItemsContainer/>
			<div style={{backgroundColor:'#e8d3d0'}}>
			<br /><br />
			<SliderDrinks />
			<br /><br />
			</div>
		</Layout>
	)
};

export default Cart;
