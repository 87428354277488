function DataSingleRemove(props) {
  window.dataLayer.push({ ecommerce: null });

  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      value: parseFloat(
        Number(props.currencyPrice.replace("€", "")).toFixed(2)
      ),
      items: [
        {
          item_id: props.id,
          item_name: props.name,
          price: parseFloat(
            Number(props.currencyPrice.replace("€", "")).toFixed(2)
          ),
          quantity: 1,
          item_brand: "Shroom",
          item_category: props.productCategories
            ? props.productCategories.nodes[0].name
            : "",
        },
      ],
      currency: "EUR",
    },
  });
}

export default DataSingleRemove;
