import Link from "gatsby-link";
import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import {
  getFormattedCart,
  getUpdatedItems,
  isUserLoggedIn,
} from "../../../utils/functions";
import CartItem from "../cart-item";
import CheckoutButton from "../../checkout-btn";
import { v4 } from "uuid";
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_CART from "../../../mutations/update-cart";
import GET_CART from "../../../queries/get-cart";
import CLEAR_CART_MUTATION from "../../../mutations/clear-cart";
import ViewCart from "../../data-layer/view-cart";
import DataRemove from "../../data-layer/remove-from-cart";
import DataSingleRemove from "../../data-layer/remove-single-cart";

const CartItemsContainer = () => {
  const [cart, setCart] = useContext(AppContext);
  const [requestError, setRequestError] = useState(null);

  // Get Cart Data.
  const { data, refetch } = useQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      // console.warn( 'completed GET_CART', data );

      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data);
      localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart));

      // Update cart data in React Context.
      setCart(updatedCart);
      //console.log(cart)
    },
  });

  // Update Cart Mutation.
  const [updateCart, { loading: updateCartProcessing }] = useMutation(
    UPDATE_CART,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        if (error) {
          setRequestError(error.graphQLErrors[0].message);
        }
      },
    }
  );

  // Clear Cart Mutation.
  const [clearCart, { loading: clearCartProcessing }] = useMutation(
    CLEAR_CART_MUTATION,
    {
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        if (error) {
          setRequestError(error.graphQLErrors[0].message);
        }
      },
    }
  );

  /*
   * Handle remove product click.
   *
   * @param {Object} event event
   * @param {Integer} Product Id.
   *
   * @return {void}
   */
  const handleRemoveProductClick = (event, item, cartKey, products) => {
    event.stopPropagation();
    DataSingleRemove(item);
    if (products.length) {
      // By passing the newQty to 0 in updateCart Mutation, it will remove the item.
      const newQty = 0;
      const updatedItems = getUpdatedItems(products, newQty, cartKey);

      updateCart({
        variables: {
          input: {
            clientMutationId: v4(),
            items: updatedItems,
          },
        },
      });
    }
  };

  // Clear the entire cart.
  const handleClearCart = (event) => {
    event.stopPropagation();
    // console.log(cart)
    if (clearCartProcessing) {
      return;
    }

    clearCart({
      variables: {
        input: {
          clientMutationId: v4(),
          all: true,
        },
      },
    });
    DataRemove(cart);
  };

  // let coupon = isCoupon()
  //console.log(coupon.amount, coupon.discountType, getFormattedDate(coupon.dateExpiry).replace(/-/g,'.'))

  let authData = isUserLoggedIn();

  return (
    <div className="content-wrap-cart">
      <div style={{ textAlign: "center", padding: "0 10%" }}>
        <h1 className="mt-5 woo-next-cart-heading spec-h">Cart</h1>
        <p>
          This is the cart's page, you can change the quantity of items and
          review your order here.
          <br />
          <br />
          Do you want to add more items?{" "}
          <Link className="text-primary" to="/">
            Return to the shop
          </Link>
          . <strong>If the order seems fine, proceed to checkout</strong>.<br />
          <br /> On the checkout page you can set your desired{" "}
          <b>
            payment currency (EUR or ZŁ), shipping and billing details, see
            shipping costs and apply coupon codes
          </b>
          .
        </p>
      </div>
      {cart ? (
        <div className="container woo-next-cart-wrapper">
          <ViewCart props={cart} />
          <h2 className="spec-h">Cart Items</h2>
          <p>
            Review the items in your cart or change their quantity, before
            checkout.
          </p>
          <div className="woo-next-cart-table-row row">
            <div className="woo-next-cart-table col-md-8 mb-md-0 mb-5">
              {cart.products.length &&
                cart.products.map((item) => (
                  <CartItem
                    item={item}
                    updateCartProcessing={updateCartProcessing}
                    products={cart.products}
                    handleRemoveProductClick={handleRemoveProductClick}
                    updateCart={updateCart}
                  />
                ))}
              {/*Clear entire cart*/}
              <div className="clear-cart">
                <button
                  className="btn btn-danger "
                  onClick={(event) => handleClearCart(event)}
                  disabled={clearCartProcessing}
                >
                  <span className="woo-next-cart">Clear Cart</span>
                  <i className="fa fa-arrow-alt-right" />
                </button>
                {clearCartProcessing ? <p>Clearing...</p> : ""}

                <Link to="/#archive" style={{ marginLeft: "1em" }}>
                  <button className="btn btn-primary woo-next-large-black-btn">
                    <span className="woo-next-cart-checkout-txt">
                      Return to shop
                    </span>
                    <i className="fas fa-long-arrow-alt-right" />
                  </button>
                </Link>
              </div>
            </div>

            {/* Display Errors if any */}
            {requestError ? (
              <div className="mt-5 row woo-next-cart-total-container">
                {" "}
                {requestError}{" "}
              </div>
            ) : (
              ""
            )}

            {/*Cart Total*/}
            <div className="woo-next-cart-total-container col-md-4">
              <h2 className="spec-h">Cart Total</h2>
              <small className="text-success">
                {authData ? (
                  authData.b2b === "1" ? (
                    <span>
                      {
                        "Congratulations! You get 25% B2B discount! Apply coupon code "
                      }
                      <b> b2bclient</b>
                      {" during checkout for the discount to apply."}
                    </span>
                  ) : null
                ) : (
                  ""
                )}
              </small>

              <table
                className="table table-hover card"
                style={{
                  backgroundColor: "whitesmoke",
                  display: "inline-table",
                }}
              >
                <tbody>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Subtotal:</td>
                    <td className="woo-next-cart-element-amt">
                      {"string" !== typeof cart.totalProductsPrice
                        ? (
                            Number(cart.subtotal) -
                            Number(cart.discountTax.replace("zł", ""))
                          ).toFixed(2) +
                          "zł | " +
                          cart.totalProductsPriceEu
                        : (
                            Number(cart.subtotal) -
                            Number(cart.discountTax.replace("zł", ""))
                          ).toFixed(2) +
                          "zł | " +
                          cart.totalProductsPriceEu}
                    </td>
                  </tr>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Shipping:</td>
                    <td className="woo-next-cart-element-amt">
                      {Number(cart.subtotal) > 200 ||
                      Number(cart.totalProductsPriceEu.replace("€", "")) >
                        100 ? (
                        <sub>
                          Free shipping available on the checkout page for
                          orders over 200PLN or 100€.
                        </sub>
                      ) : (
                        cart.shippingTotal + " | 8.00€"
                      )}
                    </td>
                  </tr>
                  <tr className="table-light">
                    <td className="woo-next-cart-element-total">Total:</td>
                    <td className="woo-next-cart-element-amt">
                      {Number(
                        cart.totalProductsPrice.replace("zł", "")
                      ).toFixed(2) +
                        "zł | " +
                        cart.totalEuWithShipping}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ textAlign: "center" }}>
                <CheckoutButton color={"white"} />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="container mt-5"
          style={{ height: "72vh", textAlign: "center" }}
        >
          <h2>No items in the cart</h2>
          <p>Add new products to start shopping.</p>
          <Link to="/">
            <button className="btn btn-outline-dark">
              <span className="woo-next-cart-checkout-txt">Return to shop</span>
              <i className="fas fa-long-arrow-alt-right" />
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default CartItemsContainer;
