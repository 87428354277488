function DataRemove(props) {
  window.dataLayer.push({ ecommerce: null });

  let items = [];
  // TODO:  add product cats
  props.products.forEach((item, i) => {
    //console.log(item)
    let product = {
      item_id: item.id,
      item_name: item.name,
      price: parseFloat(
        Number(props.currencyPrice.replace("€", "")).toFixed(2)
      ),
      quantity: item.qty,
      item_brand: "Shroom",
      item_category: item.productCategories
        ? item.productCategories.nodes[0].name
        : "",
    };
    items.push(product);
  });

  //console.log(items)

  window.dataLayer.push({
    event: "remove_from_cart",
    ecommerce: {
      value: parseFloat(
        Number(props.totalProductsPriceEu.replace("€", "")).toFixed(2)
      ),
      currency: "EUR",
      items: items,
    },
  });
}

export default DataRemove;
